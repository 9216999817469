import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';
import Preloader from '../../components/Preloader';
import {
  hasRequiredPersonalSectionFields,
  hasRequiredProfileSectionFields,
} from '../../helpers/user';
import { USER_STATUSES } from '../../helpers/constants';
import { NOTIFICATIONS } from '../../components/Notification/list';
import PaymentModule from '../../containers/PaymentModule';
import NotificationBlock from '../../containers/NotificationBlock';
import MiniFaqs, { MINIFAQ_SOURCES } from '../../containers/MiniFaqs';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { PendingTransactionsWarning } from '../../components/Warnings/PendingTransactionsWarning';
import Analytics from '../../analyticsGA';
import { isBefore } from '../../helpers/date';
import './index.scss';

const FundsAddPage = () => {
  const notifications = [];
  const location = useLocation();
  const { session_id } = qs.parse(location.search.replace('?', ''));
  const { data: profile, loading } = useSelector(
    state => state.profileStore.profile,
  );

  useEffect(() => {
    Analytics.pageNavigation('Cash');
    Analytics.addCashPage();
  }, []);

  if (!profile || loading) {
    return (
      <div className="funds_add_page">
        <Preloader className="funds_add_page__preloader" />
      </div>
    );
  }

  const canAddCash =
    [USER_STATUSES.verified].includes(profile.status) &&
    hasRequiredPersonalSectionFields(profile) &&
    hasRequiredProfileSectionFields(profile);

  if (!canAddCash) {
    notifications.push(NOTIFICATIONS.provide_your_details_for_transactions());
  }

  isBefore('2024-12-01') && notifications.push(NOTIFICATIONS.black_friday());

  if (profile?.status === USER_STATUSES.suspended) {
    return (
      <div className="funds_add_page" style={{ minHeight: 0 }}>
        <SuspendResidenceWarning />
      </div>
    );
  }

  return (
    <div className="funds_add_page">
      <NotificationBlock
        className="funds_add_page__notifications"
        notifications={notifications}
      />

      {!session_id && (
        <PendingTransactionsWarning className="funds_add_page__pending" />
      )}

      {canAddCash && <PaymentModule className="funds_add_page__payment" />}
      <MiniFaqs source={MINIFAQ_SOURCES.addCash} />
    </div>
  );
};

export default FundsAddPage;
