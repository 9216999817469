import React from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import {
  DATE_FORMAT_DD_MMM,
  DATE_FORMAT_DD_MMMM_YYYY,
  DATE_FORMAT_MMMM,
  DATE_REQUEST,
} from '../../helpers/common';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import { CONTACT_EMAIL, TIMEZONE_ESTONIA } from '../../helpers/constants';
import {
  countdownForNotification,
  prettyDate,
  prettyFloatMoney,
  prettyMoney,
} from '../../helpers/utils';

export const NOTIFICATIONS = {
  next_round_starts: start_date => idx => (
    <li key={idx}>
      The next round starts{' '}
      {countdownForNotification(
        momentTZ.tz(start_date, TIMEZONE_ESTONIA).add(12, 'hours'),
      )}
    </li>
  ),
  loan_disbursement_starts: end_date => idx => (
    <li key={idx}>
      Loan disbursement starts{' '}
      {moment(end_date).add(1, 'day').format(DATE_FORMAT_DD_MMM)}, 00:00 GMT
    </li>
  ),
  change_allocation_settings_until: end_date => idx => (
    <li key={idx}>
      You can change cash allocation settings{' '}
      {countdownForNotification(
        moment(end_date, DATE_REQUEST).add(1, 'day').format(DATE_REQUEST),
      )}
    </li>
  ),
  change_investment_strategy: end_date => idx => (
    <li key={idx}>
      You can change the investment strategy until{' '}
      {moment(end_date).format(DATE_FORMAT_DD_MMM)}, 23:59 GMT
    </li>
  ),
  change_settings_until_end: () => idx => (
    <li key={idx}>
      You can add more funds to the investment until the end of the round.
    </li>
  ),
  add_cash_to_account: () => idx => (
    <li key={idx}>
      Add cash to your account and then allocate it among loan groups.
      <MyLink href={APP_LINKS.addFunds} className="c-grey">
        Add cash →
      </MyLink>
    </li>
  ),
  founders_reached_goal: () => idx => (
    <li key={idx}>
      Founders reached their fundraising goals. It means that investing is not
      available in this round anymore. Please take part in the next round.
    </li>
  ),
  result_being_processed: () => idx => (
    <li key={idx}>
      The results of the round are being processed, please wait a bit.
    </li>
  ),
  investment_strategy_restrictive: () => idx => (
    <li key={idx}>
      Could your investment strategy be too restrictive? Email to our support
      team at&nbsp;
      <MyLink className="email" href={`mailto:${CONTACT_EMAIL}`} isExternal>
        {CONTACT_EMAIL}
      </MyLink>
    </li>
  ),
  unallocated_cash_reinvested: amount => idx => (
    <li key={idx}>
      Unallocated cash of {prettyFloatMoney(amount)} will be automatically
      reinvested in businesses within the same loan group in the next round.{' '}
      <MyLink
        href={`${APP_LINKS.allocateCash}/?to=reinvest`}
        className="c-grey"
      >
        Change preferences →
      </MyLink>
    </li>
  ),
  round_results: end_date => idx => (
    <li key={idx}>
      The round results will be processed{' '}
      <span className="f-500">
        {countdownForNotification(
          moment(end_date, DATE_REQUEST).add(1, 'day').format(DATE_REQUEST),
        )}
      </span>
      .
    </li>
  ),
  transactions_available_to_verified_users: () => idx => (
    <li key={idx}>
      Transactions are only available to verified users.{' '}
      <MyLink href={APP_LINKS.profile} className="c-grey underline">
        Complete the verification process →
      </MyLink>
    </li>
  ),
  checking_transactions_available_to_verified_users: () => idx => (
    <li key={idx}>
      Transactions are only available to verified users. We are currently
      checking your profile information – it may take up to two business days.
      We will notify you by email when the results are ready.
    </li>
  ),
  provide_your_details_for_transactions: () => idx => (
    <li key={idx}>
      Provide your details for transactions.{' '}
      <MyLink href={APP_LINKS.dashboard} className="c-grey underline">
        Provide details →
      </MyLink>
    </li>
  ),
  verify_phone_number: () => idx => (
    <li key={idx}>
      Verify your phone number to complete a registration process.{' '}
      <MyLink
        href={APP_LINKS.profile + '/#phone_verification'}
        className="c-grey underline"
      >
        Verify →
      </MyLink>
    </li>
  ),
  verify_email: () => idx => (
    <li key={idx}>
      Verify your email to complete a registration process.{' '}
      <MyLink
        href={APP_LINKS.profile + '/#email_verification'}
        className="c-grey underline"
      >
        Verify →
      </MyLink>
    </li>
  ),
  verify_phone_number_to_join_round: () => idx => (
    <li key={idx}>
      Verify your phone number to join the round.{' '}
      <MyLink
        href={APP_LINKS.profile + '/#phone_verification'}
        className="c-grey underline"
      >
        Verify →
      </MyLink>
    </li>
  ),
  bank_accounts_available_to_verified_users: () => idx => (
    <li key={idx}>
      Bank accounts are only available to verified users.{' '}
      <MyLink href={APP_LINKS.profile} className="c-grey underline">
        Complete the verification process →
      </MyLink>
    </li>
  ),
  round_ended_earlier: () => idx => (
    <li key={idx}>
      The recent round ended earlier than expected on 14th September, achieving
      a fantastic result of{' '}
      <span className="nowrap">{prettyMoney(373400)}</span>. We successfully
      funded 21 brands, with 981 investors participating. Get ready for the next
      round, which starts on 1st October. We are aiming for even bigger results!
    </li>
  ),
  congratulations_round_has_been_successfully_completed: () => idx => (
    <li key={idx}>
      Congratulations! The round has been successfully completed.{' '}
      <MyLink to={APP_LINKS.loans} style={{ color: '#9E98A3' }}>
        You can view the results here →
      </MyLink>
    </li>
  ),
  round_has_started: () => idx => (
    <li key={idx}>
      The round has started!{' '}
      <MyLink to={APP_LINKS.investing} style={{ color: '#9E98A3' }}>
        Get to know this month&apos;s batch →
      </MyLink>
    </li>
  ),
  bonus_can_be_invested_group_a_only: bonus_amount => idx => (
    <li key={idx}>
      Your {prettyFloatMoney(bonus_amount)} Scramble bonus can be invested in
      Group A only.
    </li>
  ),
  you_can_modify_investing_until: date => idx => (
    <li key={idx}>
      You can modify your investment settings{' '}
      <span className="f-500">
        until {prettyDate(moment(date), DATE_FORMAT_DD_MMMM_YYYY)}
      </span>
      . After this date, allocated amounts in groups can&apos;t be reduced; you
      can only increase the allocated amount.
    </li>
  ),
  now_you_can_add_more_funds: () => idx => (
    <li key={idx}>
      Now you can only add more funds to the investment until the end of the
      round.
    </li>
  ),
  results_will_be_processed: end_date => idx => (
    <li key={idx}>
      The results of the round will be processed on{' '}
      <span className="f-500">
        {prettyDate(moment(end_date).add(1, 'day'), DATE_FORMAT_DD_MMMM_YYYY)}
      </span>
      .
    </li>
  ),
  october_weekends: () => idx => (
    <li key={idx}>
      Please note! Due to the fact that the 5th and 6th of October are weekends
      the monthly payment for October will be made on{' '}
      <span className="f-500">7 October</span>. More detailed information in the
      section{' '}
      <MyLink to={APP_LINKS.loansSchedule} underline>
        Repayment schedule
      </MyLink>
      .
    </li>
  ),
  regular_top_ups: () => idx => (
    <li key={idx}>
      Why regular Top-ups are the key to earning higher returns?{' '}
      <MyLink
        href="https://investor.scrambleup.com/blog/why-topping-up-with-100-is-key-to-earning-higher-returns"
        underline
        isExternal
        style={{ color: '#9E98A3' }}
      >
        Find out here
      </MyLink>
    </li>
  ),
  black_friday: () => idx => (
    <li key={idx}>
      Anti Black Friday Deal! Top up your account between{' '}
      <span className="f-500">November 26-30</span> and earn a{' '}
      <span className="f-500">+1%</span> bonus on your December investments!
      Read more about the promotion{' '}
      <MyLink
        href="https://investor.scrambleup.com/blog/think-beyond-discounts-invest-wisely-black-friday"
        isExternal
        newWindow
        underline
        style={{ color: '#9E98A3' }}
      >
        here →
      </MyLink>
    </li>
  ),
};
