import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationBlock from '../../NotificationBlock';
import { STRATEGIES } from '../InvSelectStrategy';
import { ParticipatingStartupsList } from '../Batches/Startup/participate';
import PriorityFoundersGroupedList from '../InvAllocateFunds/priority';
import Dropdown from '../../../components/Dropdown';
import { NOTIFICATIONS } from '../../../components/Notification/list';
import InviteFriends from '../../../components/InviteFriends';
import InvestingSummary from '../../InvestingSummary';
import { WriteReview } from '../../../components/WriteReview';
import { isVerifiedEmail, isVerifiedPhoneNumber } from '../../../helpers/user';
import { InvestingProgressBar } from '../../../components/InvestingProgressBar';
import { scrollTop } from '../../../helpers/utils';
import s from '../../../components/Forms/PaymentForm/index.module.scss';
import { BonusBanner } from '../../BonusProgram/banner';
import { getPreAllocateInfo } from '../../../store/actions/fundActions';
import { ResetInvesting } from '../components/ResetInvesting';
import { isBefore, isExpiredResetDate } from '../../../helpers/date';
import { useCheckPlannedPayments } from '../../../components/Notification/hooks/useCheckPlannedPayments';
import './index.scss';

const InvReady = ({ status, onReset, setStep }) => {
  const dispatch = useDispatch();
  const { fundraisingDates } = useSelector(state => state.dashboardStore);
  const profile = useSelector(state => state.profileStore.profile.data);
  const { hasPlannedPayment } = useCheckPlannedPayments();

  useEffect(() => {
    dispatch(getPreAllocateInfo());
    scrollTop();
  }, []);

  const notifications = [];

  !isVerifiedPhoneNumber(profile) &&
    notifications.push(NOTIFICATIONS.verify_phone_number_to_join_round());

  !isVerifiedEmail(profile) && notifications.push(NOTIFICATIONS.verify_email());

  isExpiredResetDate(status.editable_until)
    ? notifications.push(NOTIFICATIONS.now_you_can_add_more_funds())
    : notifications.push(
        NOTIFICATIONS.you_can_modify_investing_until(status.editable_until)
      );

  notifications.push(NOTIFICATIONS.results_will_be_processed(status.end_date));

  hasPlannedPayment && notifications.push(NOTIFICATIONS.october_weekends());

  fundraisingDates?.id === 101 &&
    fundraisingDates?.is_active &&
    notifications.push(NOTIFICATIONS.regular_top_ups());

  isBefore('2024-12-01') && notifications.push(NOTIFICATIONS.black_friday());

  return (
    <div className="inv-ready">
      <h1 className="f-42 f-500">Your investment settings saved</h1>
      <NotificationBlock
        className="inv-ready__notifications"
        notifications={notifications}
      />

      <BonusBanner className={s.bonus} />

      <InvestingProgressBar className="inv-ready__progress" />

      <h2 className="inv-ready__summary-header f-26 f-500">
        Your investment settings
      </h2>

      <InvestingSummary
        isReadyStep
        status={status}
        onStrategyEdit={() => {
          setStep(1);
        }}
        onLoansEdit={() => {
          setStep(2);
        }}
        className="inv-ready__summary"
      />

      <InviteFriends analyticSource="setsaved" />

      <WriteReview className="inv-ready__trustpilot" />

      <div className="inv-ready__founders">
        {status.option === STRATEGIES.standard && (
          <Dropdown title="Consumer Goods Brands batch">
            <ParticipatingStartupsList />
          </Dropdown>
        )}
        {status.option === STRATEGIES.priority && (
          <PriorityFoundersGroupedList />
        )}
      </div>

      <ResetInvesting
        status={status}
        onReset={onReset}
        className="inv-ready__reset"
      />
    </div>
  );
};

export default InvReady;
